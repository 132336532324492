@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";

@layer base {
  html {
    background: #F3F4F6;
  }
  body {
    font-family: 'Roboto', sans-serif;
  }

  .bg-chess {
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image:  repeating-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), repeating-linear-gradient(45deg, #fff 25%, #e5e5f7 25%, #e5e5f7 75%, #fff 75%, #fff);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
  }
}